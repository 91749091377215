import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Landing from './components/Landing/Landing';
import Nav from './components/Navigation/Nav';
// import Skills from './comp/Skills/Skills';
// import Contact from './comp/Contact/Contact';
// import Projects from './comp/Projects/Projects';

const App = () => {
  return (
    <Router>
      <Fragment>
        <Nav />
        <Switch>
          <Route exact path='/' component={Landing} />
          {/* <Route exact path='/skills' component={Skills} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/projects' component={Projects} /> */}
        </Switch>
      </Fragment>
    </Router>
  );
};

export default App;
