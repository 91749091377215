// Customize this 'myform.js' script and add it to your JS bundle.
// Then import it with 'import MyForm from "./myform.js"'.
// Finally, add a <MyForm/> element whereever you wish to display the form.

import React, { Fragment } from 'react';

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: '',
    };
  }

  render() {
    const { status } = this.state;
    return (
      <Fragment>
        <div className='flex justify-center p-3 m-3 shadow-xl'>
          <form
            onSubmit={this.submitForm}
            action='https://formspree.io/mdowrldk'
            method='POST'
            className='w-full max-w-lg'
          >
            <div className='flex  flex-wrap -mx-3 mb-6'>
              <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                <label
                  className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  for='grid-first-name'
                >
                  First Name
                </label>
                <input
                  className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white'
                  id='grid-first-name'
                  type='text'
                  name='text'
                  placeholder='John'
                  required
                />
                {/* <p className='text-red-500 text-xs italic'>
                Please fill out this field.
              </p> */}
              </div>
              <div className='w-full md:w-1/2 px-3'>
                <label
                  className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  for='grid-last-name'
                >
                  Last Name
                </label>
                <input
                  className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                  id='grid-last-name'
                  type='text'
                  name='text'
                  placeholder='Doe'
                  required
                />
              </div>
            </div>
            <div className='flex flex-wrap -mx-3 mb-6'>
              <div className='w-full px-3'>
                <label
                  className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  for='grid-password'
                >
                  E-mail
                </label>
                <input
                  className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                  id='email'
                  type='email'
                  name='email'
                  placeholder=' email@.com'
                  required
                />
              </div>
            </div>
            <div className='flex flex-wrap -mx-3 mb-6'>
              <div className='w-full px-3'>
                <label
                  className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  for='grid-password'
                >
                  Message
                </label>
                <textarea
                  className=' no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none'
                  id='message'
                  type='text'
                  name='message'
                  placeholder=' Message'
                  required
                ></textarea>
                {/* <p className='text-gray-600 text-xs italic'>
                Re-size can be disabled by set by resize-none / resize-y /
                resize-x / resize
              </p> */}
              </div>
            </div>
            <div className='md:flex md:items-center'>
              <div className='flex justify-center'>
                {status === 'SUCCESS' ? (
                  <p>Thanks!</p>
                ) : (
                  <button className='bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded'>
                    Submit
                  </button>
                )}

                {status === 'ERROR' && <p>Ooops! There was an error.</p>}
              </div>
              <div className='md:w-2/3'></div>
            </div>
          </form>
        </div>
        {/* old contact below */}
        {/* <div id='contact'>
          <div className='m-3 p-3 rounded overflow-hidden '>
            <div className=' justify-center'> */}
        {/* <form
                onSubmit={this.submitForm}
                action='https://formspree.io/mdowrldk'
                method='POST'
              > */}
        {/* <div className='shadow-xl p-8 m-8'>
                  <p className='flex justify-center font-bold text-xl mb-2'>
                    Contact Me
                  </p>
                  <div className=' flex justify-center hover: bg-transparent text-black-700 font-semibold px-6 py-4 '>
                    <label>Name:</label>
                    <input
                      type='text'
                      name='text'
                      placeholder=' Name'
                      required
                    /> */}
        {/* </div>
                  <div className=' flex justify-center hover: bg-transparent text-black-700 font-semibold px-6 py-4 '>
                    <label>Email:</label>
                    <input
                      type='email'
                      name='email'
                      placeholder=' email@.com'
                      required
                    />
                  </div> */}

        {/* <div className=' flex justify-center hover: bg-transparent text-black-700 font-semibold px-6 py-4 '>
                    <label>Message:</label>
                    <textarea
                      className='resize border rounded focus:outline-none focus:shadow-outline'
                      type='text'
                      name='message'
                      placeholder=' Message'
                      required
                    />
                  </div> */}

        {/* <div className='flex justify-center'>
                    {status === 'SUCCESS' ? (
                      <p>Thanks!</p>
                    ) : (
                      <button className='bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded'>
                        Submit
                      </button>
                    )}

                    {status === 'ERROR' && <p>Ooops! There was an error.</p>}
                  </div>
                </div>
              </form>
            </div> */}
        {/* </div> */}
        {/* </div> */}
      </Fragment>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: 'SUCCESS' });
      } else {
        this.setState({ status: 'ERROR' });
      }
    };
    xhr.send(data);
  }
}
