import React, { Fragment } from 'react';
// import { Link } from 'react-router-dom';

const Nav = () => {
  return (
    <Fragment>
      <header className='flex justify-center'>
        <div>
          <a href='/'>
            <span className='font-semibold text-5xl text-black tracking-tight cursor-pointer'>
              Mitch McCuen
            </span>
          </a>
        </div>
      </header>
      <nav className='flex items-center justify-between flex-wrap bg-white-500 p-6'>
        {/* <div className='block lg:hidden'>
          <button className='flex items-center px-3 py-2 border rounded text-black-200 border-white-400 hover:text-black hover:border-white'>
            <svg
              className='fill-current h-3 w-3'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <title>Menu</title>
              <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
            </svg>
          </button>
        </div> */}
        <div className='w-full block flex-grow lg:flex lg:items-center lg:w-auto'>
          <div className='text-sm lg:flex-grow flex justify-center'>
            <a
              href='/'
              className='block mt-4 lg:inline-block lg:mt-0 text-gray-500 hover:text-black mr-4 cursor-pointer'
            >
              Home
            </a>
            {/* <a
              href='/skills '
              className='block mt-4 lg:inline-block lg:mt-0 text-gray-500 hover:text-black mr-4 cursor-pointer'
            >
              Skills
            </a> */}
            {/* <a
              to='/projects '
              className='block mt-4 lg:inline-block lg:mt-0 text-gray-500 hover:text-black mr-4 cursor-pointer'
            >
              Projects
            </a> */}
            <a
              href='https://github.com/MMcCuen'
              target='_blank'
              rel='noopener noreferrer'
              className='block mt-4 lg:inline-block lg:mt-0 text-gray-500 hover:text-black mr-4 cursor-pointer'
            >
              Github
            </a>
            {/*<a*/}
            {/*  href='https://docs.google.com/document/d/1gxWdOkJUBpaPeHnW_BEx3ZJO4K2E69GqFIshG-XeCVk/edit?usp=sharing'*/}
            {/*  target='_blank'*/}
            {/*  rel='noopener noreferrer'*/}
            {/*  className='block mt-4 lg:inline-block lg:mt-0 text-gray-500 hover:text-black mr-4 cursor-pointer'*/}
            {/*>*/}
            {/*  Resume*/}
            {/*</a>*/}
            {/* <a
              href='contact'
              className='block mt-4 lg:inline-block lg:mt-0 text-gray-500 hover:text-black cursor-pointer'
            >
              Contact
            </a> */}
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Nav;
