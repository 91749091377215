import React, { Fragment } from 'react';
import reactLogo from '../../assets/react.png';
import jsLogo from '../../assets/javascript-vector-logo-2.png';
import tailwindLogo from '../../assets/tailslogo.png';
import nuxtLogo from '../../assets/nuxtLogo.png';
import mongodbLogo from '../../assets/mongologo.webp';
import nodeLogo from '../../assets/nodelogo.png';

const FavTech = () => {
  return (
    <Fragment>
      <div>
        <div className='rounded bg-white text-center px-6 py-4'>
          <div className='max-w-xl px-4 py-4 mx-auto'>
            <p className=' font-bold text-xl mb-2'>
              Some of My Favorite Tech:{' '}
            </p>
            <div className='sm:grid sm:h-32 sm:grid-flow-row sm:gap-4 sm:grid-cols-3'>
              <div className='flex flex-col justify-center px-4 py-4 mt-4 bg-white border border-gray-300 rounded sm:mt-0 shadow-lg'>
                <div>
                  <div>
                    <p className='flex items-center justify-end text-green-500 text-md'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='w-5 h-5 fill-current'
                        viewBox='0 0 24 24'
                      ></svg>
                    </p>
                  </div>
                  <div className='flex justify-center'>
                    <img
                      src={nuxtLogo}
                      alt='nuxtLogo'
                      className='-mt-6 object-center h-16 w-16'
                    />
                  </div>
                  <p className='text-lg text-center text-gray-500'>NuxtJS</p>
                </div>
              </div>

              <div className='flex flex-col justify-center px-4 py-4 mt-4 bg-white border border-gray-300 rounded sm:mt-0 shadow-lg'>
                <div>
                  <div>
                    <p className='flex items-center justify-end text-green-500 text-md'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='w-5 h-5 fill-current'
                        viewBox='0 0 24 24'
                      ></svg>
                    </p>
                  </div>
                  <div className='flex justify-center p-2'>
                    <img
                      src={jsLogo}
                      alt='jsLogo'
                      className='-mt-6 object-center  h-12 w-12'
                    />
                  </div>
                  <p className=' text-lg text-center text-gray-500'>
                    JavaScript
                  </p>
                </div>
              </div>

              <div className='flex flex-col justify-center px-4 py-4 mt-4 bg-white border border-gray-300 rounded sm:mt-0 shadow-lg'>
                <div>
                  <div>
                    <p className='flex items-center justify-end text-green-500 text-md'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='w-5 h-5 fill-current'
                        viewBox='0 0 24 24'
                      ></svg>
                    </p>
                  </div>
                  <div className='flex justify-center'>
                    <img
                      src={reactLogo}
                      alt='reactLogo'
                      className='rounded-full -mt-6 object-center h-16 w-16'
                    />
                  </div>
                  <p className='text-lg text-center text-gray-500'>ReactJS</p>
                </div>
              </div>

              <div className='flex flex-col justify-center px-4 py-4 mt-4 bg-white border border-gray-300 rounded sm:mt-0 shadow-lg'>
                <div>
                  <div>
                    <p className='flex items-center justify-end text-green-500 text-md'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='w-5 h-5 fill-current'
                        viewBox='0 0 24 24'
                      ></svg>
                    </p>
                  </div>
                  <div className='flex justify-center'>
                    <img
                      src={tailwindLogo}
                      alt='tailwindLogo'
                      className='-mt-6 object-center h-16 w-16'
                    />
                  </div>
                  <p className='text-lg text-center text-gray-500'>
                    Tailwind CSS
                  </p>
                </div>
              </div>

              <div className='flex flex-col justify-center px-4 py-4 mt-4 bg-white border border-gray-300 rounded sm:mt-0 shadow-lg'>
                <div>
                  <div>
                    <p className='flex items-center justify-end text-green-500 text-md'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='w-5 h-5 fill-current'
                        viewBox='0 0 24 24'
                      ></svg>
                    </p>
                  </div>
                  <div className='flex justify-center'>
                    <img
                      src={mongodbLogo}
                      alt='mongodbLogo'
                      className='rounded-full -mt-6 border-4 object-center object-cover border-white h-16 w-16'
                    />
                  </div>
                  <p className='text-lg text-center text-gray-500'>MongoDB</p>
                </div>
              </div>

              <div className='flex flex-col justify-center px-4 py-4 mt-4 bg-white border border-gray-300 rounded sm:mt-0 shadow-lg'>
                <div>
                  <div>
                    <p className='flex items-center justify-end text-green-500 text-md'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='w-5 h-5 fill-current'
                        viewBox='0 0 24 24'
                      ></svg>
                    </p>
                  </div>
                  <div className='flex justify-center'>
                    <img
                      src={nodeLogo}
                      alt='nodeLogo'
                      className='-mt-6 object-center h-16 w-16'
                    />
                  </div>
                  <p className='text-lg text-center text-gray-500'>NodeJS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FavTech;
