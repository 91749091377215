import React, { Fragment } from 'react';
const Projects = () => {
  return (
    <Fragment>
      <div className='flex justify-center p-5 m-5'>
        <p className=' font-bold text-xl mb-2'>My Projects</p>
      </div>
      {/*  */}
      <div className='flex justify-center'>
        <div className='md:w-1/2 lg:w-1/3 py-4 px-4 flex justify-center'>
          {' '}
          <div className=''>
            <a
              className='shadow-xl'
              href='https://festive-beaver-e345de.netlify.app/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='bg-white relative shadow p-2 rounded-lg text-gray-800 border-black-200 shadow shadow-xl hover:shadow-outline '>
                <div className='py-2 px-2'>
                  <div className=' font-bold font-title text-center'>
                    That Food Truck
                  </div>
                  <div className=' font-title text-center text-sm font-light text-gray-600 '>
                    Small blog that uses JWT to authenticate an admin then
                    allows basic CRUD operations.
                  </div>
                  <div className='text-sm font-light text-center my-2'>
                    React | JavaScript | HTML5 | Bootstrap | MongoDB | Mongoose
                    | NodeJS | Express
                  </div>
                </div>
              </div>
            </a>

            {/* <a
              className='p-2 m-2 shadow-xl'
              href='https://www.staciwilloughby.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='bg-white relative shadow p-2 rounded-lg text-gray-800 border-black-200 shadow shadow-xl hover:shadow-outline '>
                {' '}
                <div className='flex justify-center'></div>
                <div className='py-2 px-2'>
                  <div className=' font-bold font-title text-center'>
                    Staci Willoughby
                  </div>
                  <div className=' font-title text-center'>
                    Under Construction
                  </div>

                  <div className='text-sm font-light text-center my-2'>
                    React | JavaScript | HTML5 | Tailwind Css
                  </div>
                </div>
              </div>
            </a> */}
          </div>
          {/*  */}
        </div>
      </div>
      {/*  */}
    </Fragment>
  );
};

export default Projects;
