import React, { Fragment } from 'react';

const Skills = () => {
  return (
    <Fragment>
      <div className=''>
        <div className='flex justify-center shadow-lg'>
          <div className=' justify-center max-w-xl rounded overflow-hidden shadow-lg'>
            <div className='justify-center px-6 py-4'>
              <div className=' flex justify-center font-bold text-xl mb-2'>
                Skills
              </div>
              <div className='container'>
                <div>
                  <div className='justify-center relative pt-1'>
                    <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white-600 bg-transparent-200'>
                      HTML
                    </span>

                    <div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-400'>
                      <div
                        style={{ width: '40%' }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500'
                      ></div>
                    </div>
                  </div>
                  <div className='justify-center relative pt-1'>
                    <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white-600 bg-transparent-200'>
                      CSS | TailwindCSS
                    </span>

                    <div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-400'>
                      <div
                        style={{ width: '30%' }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500'
                      ></div>
                    </div>
                  </div>
                  {/*  */}
                  <div className='justify-center relative pt-1'>
                    <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white-600 bg-transparent-200'>
                      JavaScript | TypeScript
                    </span>

                    <div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-400'>
                      <div
                        style={{ width: '40%' }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500'
                      ></div>
                    </div>
                  </div>
                  {/*  */}
                  <div className='justify-center relative pt-1'>
                    <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white-600 bg-transparent-200'>
                      React
                    </span>

                    <div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-400'>
                      <div
                        style={{ width: '40%' }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500'
                      ></div>
                    </div>
                  </div>

                  {/*  */}
                  <div className='justify-center relative pt-1'>
                    <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white-600 bg-transparent-200'>
                      Angular
                    </span>

                    <div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-400'>
                      <div
                        style={{ width: '20%' }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500'
                      ></div>
                    </div>
                  </div>
                  {/*  */}
                  <div className='justify-center relative pt-1'>
                    <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white-600 bg-transparent-200'>
                      Vue | NuxtJS
                    </span>

                    <div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-400'>
                      <div
                        style={{ width: '10%' }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500'
                      ></div>
                    </div>
                  </div>
                  {/*  */}
                  <div className='justify-center relative pt-1'>
                    <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white-600 bg-transparent-200'>
                      SQL
                    </span>

                    <div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-400'>
                      <div
                        style={{ width: '20%' }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500'
                      ></div>
                    </div>
                  </div>
                  {/*  */}
                  <div className='justify-center relative pt-1'>
                    <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white-600 bg-transparent-200'>
                      MongoDB | Mongoose
                    </span>

                    <div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-400'>
                      <div
                        style={{ width: '30%' }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500'
                      ></div>
                    </div>
                  </div>
                  {/*  */}
                  <div className='justify-center relative pt-1'>
                    <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white-600 bg-transparent-200'>
                      NodeJS | Express
                    </span>

                    <div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-400'>
                      <div
                        style={{ width: '40%' }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500'
                      ></div>
                    </div>
                  </div>
                  {/*  */}
                  <div className='justify-center relative pt-1'>
                    <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white-600 bg-transparent-200'>
                      Axios
                    </span>
                    <div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-400'>
                      <div
                        style={{ width: '20%' }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500'
                      ></div>
                    </div>
                  </div>
                  {/*  */}

                  {/*  */}
                </div>
              </div>
            </div>
            {/* hide spans on small screen */}
            <div className='px-6 pt-4 pb-2 '>
              <span className='inline-block bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2  invisible md:visible'>
                Novice
              </span>
              <span className='inline-block bg-transparent rounded-full px-3 py-1 mr-2 mb-2'></span>
              <span className='inline-block bg-transparent rounded-full px-3 py-1 mr-2 mb-2'></span>
              <span className='inline-block bg-transparent rounded-full px-3 py-1 mr-2 mb-2'></span>
              <span className='inline-block bg-transparent rounded-full px-3 py-1 mr-2 mb-2'></span>
              <span className='inline-block bg-transparent rounded-full px-3 py-1 mr-2 mb-2'></span>
              <span className='inline-block bg-transparent rounded-full px-3 py-1 mr-2 mb-2'></span>
              <span className='inline-block bg-transparent rounded-full px-3 py-1 mr-2 mb-2'></span>
              <span className='inline-block bg-transparent rounded-full px-3 py-1 mr-2 mb-2'></span>
              <span className='inline-block bg-transparent rounded-full px-3 py-1 mr-2 mb-2'></span>
              <span className='inline-block bg-transparent rounded-full px-3 py-1 mr-2 mb-2'></span>
              <span className='inline-block bg-transparent rounded-full px-3 py-1 mr-2 mb-2'></span>
              <span className='inline-block bg-transparent rounded-full px-3 py-1 mr-2 mb-2'></span>

              <span className='inline-block bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 invisible md:visible'>
                Expert
              </span>
            </div>
          </div>
          {/* <p class='text-gray-darker max-w-sm mx-auto mb-6'>
          Skills:{' '}
          <span class='text-gray-500'>
          HTML5, TailwindCSS | CSS, JavaScript, TypeScript, jQuery, Axios,
          ReactJS | NextJS, AngularJS, Vue | NuxtJS, NoSQL, MySQL, NodeJS,
          Express, MongoDB, Mongoose
          </span>
          <span class='text-black'></span>
        </p> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Skills;
